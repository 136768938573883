import React from 'react';
import last from 'lodash/last';

import t from 'react-translate';
import { QuestionContext } from 'quizzes/components/context';
import { getAlphabetCharacter, removeDecimalForInteger } from 'quizzes/components/utils';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';

type Props = {
  className?: string;
};

const CorrectAnswer = (props: Props) => {
  const { className } = props;

  const { responseOptions, currentQuestionResponse } = React.useContext(QuestionContext);

  const {
    isMultipleChoiceQuestion,
    isShortAnswerQuestion,
    isNumberAnswerQuestion,
    isMatchingQuestion,
  } = useQuizModeAndQuestionType();

  const getCorrectAnswers = () => {
    if (isMultipleChoiceQuestion) {
      return responseOptions.map((option, index) => ({
        optionIdentifier: getAlphabetCharacter(index),
        option,
      })).filter(({ option }) => option.isCorrect).map(({ optionIdentifier }) => optionIdentifier);
    }
    if (isShortAnswerQuestion) {
      return responseOptions
        .filter((option) => option.optionContent !== '')
        .map((option) => option.optionContent);
    }
    if (isNumberAnswerQuestion) {
      return [
        removeDecimalForInteger(responseOptions[0].bottomOfRange),
        removeDecimalForInteger(responseOptions[0].topOfRange),
      ];
    }
    return [];
  };

  const correctAnswers = getCorrectAnswers();

  const renderCorrectAnswers = () => {
    const lastCorrectAnswer = last(correctAnswers);
    const before = correctAnswers.slice(0, correctAnswers.length - 1);

    if (isMultipleChoiceQuestion) {
      const isAdminNotSettedAnyCorrectAnswer = !currentQuestionResponse?.numberOfCorrectOptions;

      if (isAdminNotSettedAnyCorrectAnswer) {
        return `${before.join(', ')}${before.length ? t.JOIN.MANY.OR() : ''}${lastCorrectAnswer}`;
      }

      return correctAnswers.join(', ');
    }
    if (isShortAnswerQuestion) {
      if (!correctAnswers.length) {
        return t.QUIZZES.NO_CORRECT_ANSWER_DEFINED();
      }
      return `${before.join(', ')}${before.length ? t.JOIN.MANY.OR() : ''}${lastCorrectAnswer}`;
    }
    if (isNumberAnswerQuestion) {
      if (!(correctAnswers[0] || correctAnswers[1])) {
        return t.QUIZZES.NO_CORRECT_ANSWER_DEFINED();
      }
      if (correctAnswers[0] === correctAnswers[1]) {
        return correctAnswers[0];
      }
      return t.QUIZZES.CORRECT_ANSWER_RANGE(correctAnswers[0], correctAnswers[1]);
    }
    if (isMatchingQuestion) {
      return t.QUIZZES.MATCHING_QUESTION_SEE_CORRECT_ANSWER();
    }
    return null;
  };

  return (
    <div className={`text-large-body bold text-success ${className ?? ''}`}>
      {t.QUIZZES.CORRECT_ANSWER()}
      {' '}
      {renderCorrectAnswers()}
    </div>
  );
};

export default CorrectAnswer;
