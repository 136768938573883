import React from 'react';
import * as yup from 'yup';

import { GetQuestionParams } from 'redux/actions/quizzes';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import {
  QuizQuestion,
  NQuizQuestion,
  NProgressiveQuiz,
  NQuizQuestionResponse,
  ResponseOption,
} from 'redux/schemas/models/progressive-quiz';
import {
  ComponentType,
  NLectureComponent,
} from 'redux/schemas/models/lecture-component';

type ProgressiveQuizContextValue = {
  reveal: boolean;
  mode: ProgressiveQuizMode;
  questions: NQuizQuestion[];
  notMetRequirement: () => boolean;
  currentQuestionIndex: number;
  isRequirementEnabled: boolean;
  currentQuestion: NQuizQuestion;
  canMakeStructuralChanges: boolean;
  progressiveQuiz: NProgressiveQuiz;
  requiredCorrectQuestionsCount: number;
  closeModal: (skipCheck?: boolean) => void;
  scrollRef: React.MutableRefObject<HTMLDivElement>;
  setCurrentQuestionIndex: (newIndex: number) => void;
  currentQuestionResponse: NQuizQuestionResponse | null;
  forwardOnModalClose: (fn: (e: Event) => void) => () => void;
  lectureComponent: NLectureComponent<ComponentType.PROGRESSIVE_QUIZ>;
  fetchQuestion: (params?: Omit<GetQuestionParams, 'questionSetId'>) => Promise<QuizQuestion>;
  savingIndicatorTimeoutRef: React.MutableRefObject<null | NodeJS.Timeout>;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSavingStatus: React.Dispatch<React.SetStateAction<SavingIndicator>>;
};

export default React.createContext<ProgressiveQuizContextValue>(null);

type ShortAnswerState = string;
type NumberAnswerState = number;
type MultipleChoiceAnswerState = {
  [key: number]: boolean
};
export type MatchingAnswerState = {
  requiredPairsCount: number,
  pairs: {
    [optionId: number]: ResponseOption['id'][]
  }
};

export type AnswerStateType = MultipleChoiceAnswerState
| MatchingAnswerState
| ShortAnswerState
| NumberAnswerState;

type QuestionContextValue = {
  areAllOptionsCorrect?: boolean;
  currentQuestion: NQuizQuestion;
  answerState: AnswerStateType;
  setAnswerState: (answerState: AnswerStateType) => void;
  answerInputError: yup.ValidationError;
  setAnswerInputError: (answerInputError: yup.ValidationError) => void;
  responseOptions?: ResponseOption[];
  currentQuestionResponse?: NQuizQuestionResponse | null;
};

export enum SavingIndicator{
  SAVING_STATUS = 'saving',
  HIDDEN_STATUS = 'hidden',
  SUCCESS_STATUS = 'success',
  ERROR_STATUS = 'error',
}

export const QuestionContext = React.createContext<QuestionContextValue>(null);
