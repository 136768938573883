import { schema } from 'normalizr';

import {
  QuizQuestion,
  QuizSubmission,
  QuizQuestionFeedback,
  QuizQuestionResponse,
  QuizQuestionSubmission,
  ResponseOption,
} from '../models/progressive-quiz';

const QuizQuestionFeedbackSchema = new schema.Entity<QuizQuestionFeedback>('quizQuestionFeedbacks');

export const QuizQuestionOptionSchema = new schema.Entity<ResponseOption>('quizQuestionOptions');

const QuizQuestionResponseSchema = new schema.Entity<QuizQuestionResponse>('quizQuestionResponses', {
  attemptFeedback: QuizQuestionFeedbackSchema,
  feedback: [QuizQuestionOptionSchema],
});

export const QuizQuestionSchema = new schema.Entity<QuizQuestion>('quizQuestions', {
  responses: QuizQuestionResponseSchema,
  responseOptions: [QuizQuestionOptionSchema],
  attemptFeedbacks: [QuizQuestionFeedbackSchema],
});

const ProgressiveQuizSchema = new schema.Entity('progressiveQuizzes');

export const QuizQuestionSubmissionSchema = new schema.Entity<QuizQuestionSubmission>('quizQuestionSubmissions', {
  questions: QuizQuestionSchema,
  questionSet: ProgressiveQuizSchema,
});

export const QuizSubmissionSchema = new schema.Entity<QuizSubmission>('quizSubmissions', {
  questions: [QuizQuestionSchema],
  responses: [QuizQuestionResponseSchema],
});
