import React from 'react';
import { QuizQuestionType } from 'redux/schemas/models/progressive-quiz';
import { ProgressiveQuizMode } from 'quizzes/components/mode';
import ProgressiveQuizContext from 'quizzes/components/context';

const useQuizModeAndQuestionType = () => {
  const {
    mode,
    currentQuestion,
  } = React.useContext(ProgressiveQuizContext);

  const isEditMode = mode === ProgressiveQuizMode.EDIT;
  const isAnswerMode = mode === ProgressiveQuizMode.ANSWER;
  const isReviewMode = mode === ProgressiveQuizMode.REVIEW;
  const isMultipleChoiceQuestion = currentQuestion.type === QuizQuestionType.MULTIPLE_CHOICE
    || currentQuestion.type === QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER;
  const isMultipleAnswer = currentQuestion.type === QuizQuestionType.MULTIPLE_CHOICE_MULTIPLE_ANSWER;
  const isStatement = currentQuestion.type === QuizQuestionType.STATEMENT;
  const isShortAnswerQuestion = currentQuestion.type === QuizQuestionType.SHORT_TEXT_QUESTION;
  const isNumberAnswerQuestion = currentQuestion.type === QuizQuestionType.NUMBER_QUESTION;
  const isLongAnswerQuestion = currentQuestion.type === QuizQuestionType.LONG_ANSWER_QUESTION;
  const isMatchingQuestion = currentQuestion.type === QuizQuestionType.MATCHING_QUESTION;

  return {
    isEditMode,
    isAnswerMode,
    isReviewMode,
    isMultipleChoiceQuestion,
    isMultipleAnswer,
    isStatement,
    isShortAnswerQuestion,
    isNumberAnswerQuestion,
    isLongAnswerQuestion,
    isMatchingQuestion,
  };
};

export default useQuizModeAndQuestionType;
