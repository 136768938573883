import t from 'react-translate';
import { useContext, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { find, isEmpty, map } from 'underscore';
import Xarrow from 'react-xarrows';

import { MatchingQuestionResponse, ResponseOption } from 'redux/schemas/models/progressive-quiz';

import { largeSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { danger, hexToRgbaString, primary, success } from 'styles/global_defaults/colors';
import { isRtl } from 'styles/global_defaults/media-queries';

import { MatchingAnswerState, QuestionContext } from 'quizzes/components/context';
import useMatchingQuestionAnswer, { getOpionId, MatchingQuestionAnswerProvider } from 'quizzes/components/hooks/use-matching-question-answer';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import MatchingQuestionAnswerResponseOption from './matching-question-answer-response-option';

const MatchingQuestionAnswerSection = () => {
  const [selectedOption, setSelectedOption] = useState<ResponseOption>(null);
  const [hoveredId, setHoveredId] = useState<number>(null);

  const {
    answerState,
    setAnswerState,
    responseOptions,
  } = useContext(QuestionContext);

  const {
    isReviewMode,
  } = useQuizModeAndQuestionType();

  const {
    pairs,
    isSubmittedMode,
    checkOptionInPairs,
  } = useMatchingQuestionAnswer();

  const questions = useMemo(() => responseOptions.filter((option) => option.parent), [responseOptions]);
  const options = useMemo(() => responseOptions.filter((option) => !option.parent), [responseOptions]);

  const canShowCorrectAnswers = () => {
    if (isReviewMode && hoveredId) {
      const hoverOption = find(responseOptions, (option) => option.id === hoveredId);
      return !hoverOption?.isCorrect;
    }

    return false;
  };

  const getCorrectPairs = () => {
    const newPairs = {};
    if (responseOptions.length > 0) {
      responseOptions.forEach((option) => {
        if (option.associationId) {
          newPairs[option.associationId] = [option.id];
        }
      });
    }

    return newPairs;
  };

  const getPairs = (): MatchingQuestionResponse => {
    if (canShowCorrectAnswers()) {
      return getCorrectPairs();
    }

    return pairs;
  };

  // Setting the requiredPairsCount in answer state when submitting the question
  useEffect(() => {
    setAnswerState({ ...answerState as MatchingAnswerState, requiredPairsCount: questions.length });
  }, [questions.length, isSubmittedMode]);

  const styles = css`
    gap: ${largeSpacing}px;

    .pair-container {
      gap: ${standardSpacing}px;
    }
  `;

  const getArrowColor = (questionId: number) => {
    if (isReviewMode || isSubmittedMode) {
      const keyOption = find(responseOptions, (option) => option.id === questionId);
      return keyOption.isCorrect ? success : danger;
    }

    // Using blured color for arrows
    // When selected an option
    if (!isEmpty(selectedOption) || (
      // When hovering a pair
      (hoveredId && checkOptionInPairs(hoveredId))
      && ![questionId, ...pairs[questionId]].includes(hoveredId)
    )) {
      return hexToRgbaString(primary, 0.2);
    }

    return primary;
  };

  const canUseArrowDashes = (key: number) => {
    if (isReviewMode || isSubmittedMode) {
      const keyOption = find(responseOptions, (option) => option.id === key);
      return !keyOption.isCorrect;
    }

    return false;
  };

  return (
    <MatchingQuestionAnswerProvider
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      hoveredId={hoveredId}
      setHoveredId={setHoveredId}
      questions={questions}
    >
      <div css={styles} className='d-flex flex-column'>
        {!isReviewMode && (
          <p className='text-small text-center text-gray-1 font-weight-bold'>
            {t.QUIZZES.MATCHING_QUESTION.ANSWER_HEADER()}
          </p>
        )}
        <div className='pair-container d-flex flex-column mb-4'>
          {Array.from(questions).map((question, index) => (
            !isEmpty(question) && !isEmpty(options?.[index]) && (
              <div
                key={question.id}
                css={styles}
                className='pair-row d-flex align-items-stretch justify-content-between'
              >
                <MatchingQuestionAnswerResponseOption
                  responseOption={question}
                />
                <MatchingQuestionAnswerResponseOption
                  responseOption={options?.[index]}
                />
              </div>
            )))}
        </div>
        {map(getPairs(), (optionIds, qId) => (
          optionIds.map((oId) => (
            <Xarrow
              key={qId}
              start={getOpionId(+qId)} // Left start option id
              end={getOpionId(oId)} // Right end option id
              startAnchor={isRtl() ? 'left' : 'right'}
              endAnchor={isRtl() ? 'right' : 'left'}
              strokeWidth={2}
              color={getArrowColor(+qId)}
              showHead={false} // using the end dots separatly in each option
              showTail={false}
              path='straight'
              dashness={canUseArrowDashes(+qId)}
            />
          ))
        ))}
      </div>
    </MatchingQuestionAnswerProvider>
  );
};

export default MatchingQuestionAnswerSection;
