import { Normalized } from 'redux/schemas/normalized';
import { TimelineQuiz } from 'redux/schemas/models/quiz';
import { FileUpload, UploadedFile } from 'redux/schemas/models/file-uploads';
import { MatchingAnswerState } from 'quizzes/components/context';
import { MinimalActivity, ActivityType, ActivityProgress } from './activity';
import { PointsConfiguration } from './points-configuration';
import { Communication } from './course-communication';

export enum QuizQuestionType {
  MULTIPLE_CHOICE = 'MultipleChoiceQuestion',
  MULTIPLE_CHOICE_MULTIPLE_ANSWER = 'MultipleChoiceMultipleAnswerQuestion',
  STATEMENT = 'ReadOnlyQuestion',
  SHORT_TEXT_QUESTION = 'MultipleShortAnswerQuestion',
  LONG_ANSWER_QUESTION = 'RichTextQuestion',
  NUMBER_QUESTION = 'NumberQuestion',
  MATCHING_QUESTION = 'MatchingQuestion',
}

export interface MinimalProgressiveQuizActivity extends MinimalActivity {
  type: ActivityType.PROGRESSIVE_QUIZ
}

export type TimelineProgressiveQuiz = {
  id: number;
  title: string;
  ownerTitle?: string;
  titleIndex?: number;
  isRequiredForCompletion: boolean;
  expirationDate: string | null;
  releaseDate: string;
  answerableQuestionsCount: number;
  pointsConfiguration: PointsConfiguration;
  activityType: string;
  totalPoints: number[];
  pointsReceived: number;
  releaseResultsAt?: string;
  timeLimit?: number;
  timeInterval?: string;
  duration?: number;
  timeRemaining?: number;
  expired?: boolean;
  catalogId: string;
  courseCatalogId: string,
  courseId: number;
  lecturePageId: number;
  lectureComponentId: number;

  communications?: Communication[],
  communicationsCount?: number,

  // These 4 props may only exist on the lecture page component
  hardDeadline: boolean,
  graded: true,
  maximumAttempts: number,
  isTodo: false,

  hasStructuralIssues?: boolean;
} & MinimalProgressiveQuizActivity;

export type ResponseOption = {
  id: number;
  index: number;
  questionId: number;
  isCorrect: boolean;
  optionContent: string;
  optionFeedback: null | string;
  explanation: string;
  secondaryExplanation: string;
  bottomOfRange: string,
  topOfRange: string,

  // Matching question fields
  parent: boolean;
  associationId: number

  // These properties seem to be from quiz 1.0 so not using them for now:
  // 'read_only': false,
  // 'votes_count': null,
  // 'voters': null,
};

export type ResponseOptionsNormalized = Record<number, ResponseOption>;

export type QuizQuestionFeedback = {
  id: number;
  index: number;
  feedbackText: string;
};

export type QuizQuestionFeedbackNormalized = Record<number, QuizQuestionFeedback>;

export type MultiChoiceMultiAnswerQuestionResponse = number[];
type MultipleChoiceQuestionResponse = number | MultiChoiceMultiAnswerQuestionResponse;
type ShortAnswerQuestionResponse = string;
type NumberAnswerQuestionResponse = number;
export type MatchingQuestionResponse = MatchingAnswerState['pairs'];

export type QuizResponse =
  MultipleChoiceQuestionResponse
  | ShortAnswerQuestionResponse
  | NumberAnswerQuestionResponse
  | MatchingQuestionResponse;

export type QuizQuestionResponse = {
  id: number;
  isCorrect: false;
  questionId: number;
  numberOfAttempts: number;
  response: QuizResponse;
  numberOfCorrectOptions: number;
  attemptFeedback: QuizQuestionFeedback;
  feedback: ResponseOption[];
  // Not using these for now:
  // questionSubmissionRevisionId: number;
};

export type NQuizQuestionResponse = Normalized<QuizQuestionResponse, 'feedback' | 'attemptFeedback'>;

export type QuizQuestionResponseNormalized = Record<number, NQuizQuestionResponse>;

type QuestionFileUpload = {
  file: UploadedFile | null;
};

export type QuizQuestion = {
  id: number;
  isRequired: boolean;
  questionText: string;
  questionIndex: number;
  questionSetId: number;
  type: QuizQuestionType;
  questionFileUpload?: QuestionFileUpload;
  responses: null | QuizQuestionResponse;
  previousAttemptResponses?: number | null;
  // Admin will only get this property
  correctAnswersCount?: number;
  totalQuestionAttempts: number;
  completedQuestionAttempts: number;
  // Admin will only get this property
  attemptFeedbacks?: QuizQuestionFeedback[];
  // Learner will only get this property
  attemptFeedback?: QuizQuestionFeedback;
  responseOptions: ResponseOption[];
  // TODO: Remove this property as it doesn't exist
  retry: number;
  displayIndex?: number | null;
  // These properties seem to be from quiz 1.0 so not using them for now:
  // 'second': null,
  // 'children_questions': null,
  // 'num_rows': null,
  // 'num_columns': null,
  // 'horizontal_axis_label': null,
  // 'vertical_axis_label': null,
  // 'left_limit': null,
  // 'right_limit': null,
};

export type NQuizQuestion = Normalized<QuizQuestion, 'responseOptions' | 'attemptFeedbacks' | 'responses'>;

export type QuizQuestionsNormalized = Record<number, NQuizQuestion>;

export type QuizSubmission = {
  id: number;
  leaderboardRank: number;
  leaderboardPoints: number;
  type: 'questionsetsubmission';
  pointsReceived: number | null;
  responses: QuizQuestionResponse[];
  priorLeaderboardRank: number | null;
  questions: ResponseOption[];
  quiz: {
    id: number;
    progress: ActivityProgress;
    correctAnswersCount: number;
  },
};

export type NQuizSubmission = Normalized<QuizSubmission, 'responses' | 'questions'>;

export type QuizSubmissionsNormalized = Record<number, NQuizSubmission>;

export type QuizQuestionSubmission = {
  id: number;
  questionSetId: number;
  questions: QuizQuestion;
  type: 'questionsubmission';
  questionSet: ProgressiveQuiz;
};

export type NQuizQuestionSubmission = Normalized<QuizQuestionSubmission, 'questions' | 'questionSet'>;

export type QuizQuestionSubmissionNormalized = Record<number, NQuizQuestionSubmission>;

export type ProgressiveQuiz = {
  resumeQuiz: boolean;
  totalQuestions: number;
  attemptsCompleted: number;
  questions: QuizQuestion[];
  hasCorrectAnswer: boolean;
  hasFailedQuestion: boolean;
  hasIncorrectAnswer: boolean;
  correctAnswersCount: number;
  questionMaximumAttempts: number;
  learnerStartedSubmission: boolean;
  answerableQuestionsCount: number;
  submission: QuizSubmission;
} & TimelineQuiz;

export type NProgressiveQuiz = Normalized<ProgressiveQuiz, 'communications' | 'questions' | 'pointsConfiguration'>;

export interface ProgressiveQuizzesNormalized { [id: string]: NProgressiveQuiz }
