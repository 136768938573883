import t from 'react-translate';
import { useCallback, useContext, useMemo } from 'react';
import { css } from '@emotion/react';
import { isEmpty } from 'underscore';

import { useAppDispatch } from 'redux/store';
import { getFlatCourseAliases } from 'redux/selectors/course';
import { useSelector } from 'react-redux';
import { ResponseOption } from 'redux/schemas/models/progressive-quiz';
import { RootState } from 'redux/schemas';
import { getQuizQuestionOptions } from 'redux/selectors/quizzes';
import { addMultipleQuizQuestionOption } from 'redux/actions/quizzes';

import { largeSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';

import ClickableContainer from 'components/clickable-container';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import ProgressiveQuizContext, { SavingIndicator } from 'quizzes/components/context';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import MatchingQuestionSettingPairRow from './matching-question-setting-pair-row';

const MAX_PAIR_COUNT = 10;

export type Pair = {
  id: number,
  parentId: number,
  childId: number,
};

const MatchingQuestionSettingSection = () => {
  const {
    currentQuestion,
    savingIndicatorTimeoutRef,
    canMakeStructuralChanges,
    setSavingStatus,
  } = useContext(ProgressiveQuizContext);
  const { registerSaving } = useContext(SavingRegistryContext);

  const dispatch = useAppDispatch();
  const courseAliases = useSelector(getFlatCourseAliases);

  const responseOptions: ResponseOption[] = useSelector(
    (state: RootState) => getQuizQuestionOptions(state, currentQuestion?.id),
  );

  const styles = css`
    width: 750px; // inputs width (720) + delete button (30)
    gap: ${largeSpacing}px;

    .pair-container {
      gap: ${standardSpacing}px;
    }
  `;

  /**
   * We are creating pairs from the responseOptions, designating the left side
   * as the parent and the right side as the child. A response option will be
   * considered a child if it has a value in the associationId field.
   */
  const getPairs = useCallback(() => {
    const newPairs = [];
    if (responseOptions.length > 0) {
      responseOptions.forEach((option) => {
        if (option.associationId) {
          newPairs.push({
            id: `${option.associationId}-${option.id}`,
            parentId: option.associationId,
            childId: option.id,
          });
        }
      });
    }

    return newPairs;
  }, [responseOptions.length]);

  const pairs = useMemo(getPairs, [getPairs]);

  const AddNewPair = () => {
    const unregister = registerSaving();
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);

    dispatch(addMultipleQuizQuestionOption({ questionId: currentQuestion.id })).then((res) => {
      if (isEmpty(res.error)) {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      } else {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }
    }).finally(() => {
      unregister();
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const canDisableAddPair = pairs.length >= MAX_PAIR_COUNT || !canMakeStructuralChanges;

  return (
    <div css={styles} className='d-flex flex-column'>
      <h6 className='text-small text-center text-gray-1 font-weight-bold my-0'>
        {t.QUIZZES.MATCHING_QUESTION.CREATION_HEADER(courseAliases)}
      </h6>
      <div className='pair-container d-flex flex-column'>
        {pairs.map((pair, index) => (
          <MatchingQuestionSettingPairRow
            key={pair.id}
            pair={pair}
            disableDelete={pairs.length === 1}
            arrowEndText={index + 1}
          />
        ))}
      </div>
      <NvTooltip
        enabled={canDisableAddPair}
        text={!canMakeStructuralChanges
          ? t.QUIZZES.ALREADY_RELEASED.OPTIONS({ ...courseAliases, isPair: true })
          : t.QUIZZES.MATCHING_QUESTION.CANNOT_ADD_PAIR(MAX_PAIR_COUNT)}
      >
        <ClickableContainer
          onClick={AddNewPair}
          className={`text-regular add-option align-self-start text-${canDisableAddPair ? 'gray-4' : 'primary'}`}
          disabled={canDisableAddPair}
          isFocusable={canDisableAddPair}
        >
          <NvIcon icon='add' size='small' className={`mr-2 text-${canDisableAddPair ? 'gray-5' : 'primary'}`} />
          {t.QUIZZES.MATCHING_QUESTION.ADD_PAIR()}
        </ClickableContainer>
      </NvTooltip>
    </div>
  );
};

export default MatchingQuestionSettingSection;
