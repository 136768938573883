import React, { useCallback, useContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { AngularServicesContext } from 'react-app';
import { isEmpty } from 'underscore';
import Xarrow from 'react-xarrows';
import t from 'react-translate';

import { doubleSpacing, halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { danger, gray5, gray6, hexToRgbaString, primary, success } from 'styles/global_defaults/colors';
import { isRtl } from 'styles/global_defaults/media-queries';

import { ResponseOption } from 'redux/schemas/models/progressive-quiz';

import ClickableContainer from 'components/clickable-container';
import ResponsivelyEmbeddedAngularHTML from 'shared/components/responsively-embedded-angular-html';
import NvTooltip from 'shared/components/nv-tooltip';
import useMatchingQuestionAnswer, { getOpionId, getSanitizedContent, MatchingQuestionAnswerContext } from 'quizzes/components/hooks/use-matching-question-answer';
import ProgressiveQuizContext, { MatchingAnswerState, QuestionContext } from 'quizzes/components/context';
import NvIcon from 'shared/components/nv-icon';
import useQuizModeAndQuestionType from 'quizzes/hooks/use-quiz-mode-and-question-type';
import { MAX_INPUT_HEIGHT } from './question-setting-pair-response-option';

export type MatchingQuestionAnswerResponseOptionProps = {
  responseOption: ResponseOption,
};

const MatchingQuestionAnswerResponseOption = (props: MatchingQuestionAnswerResponseOptionProps) => {
  const { responseOption } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const angularServices = useContext(AngularServicesContext);
  const {
    selectedOption,
    setSelectedOption,
    hoveredId,
    setHoveredId,
  } = useContext(MatchingQuestionAnswerContext);

  const {
    answerState,
    setAnswerState,
  } = useContext(QuestionContext);

  const {
    currentQuestionResponse,
  } = useContext(ProgressiveQuizContext);

  const { id } = responseOption;

  const {
    isAnswerMode,
    isReviewMode,
  } = useQuizModeAndQuestionType();

  const {
    pairs,
    isSubmittedMode,
    checkOptionInPairs,
    getPairedOptionsId,
    checkCanDimOption,
    isQuestion,
    getArrowText,
  } = useMatchingQuestionAnswer();

  const checkContentOverflow = useCallback(() => {
    const element = document.querySelector(`[id="${getOpionId(id)}"] .option-content`);
    const contentHeight = element?.scrollHeight;

    if (contentHeight > MAX_INPUT_HEIGHT) {
      setShowTooltip(true);
    }
  }, [id]);

  useEffect(() => {
    // Checking the the initial content overflow only after DOM has rendered
    requestAnimationFrame(checkContentOverflow);
  }, [checkContentOverflow]);

  const onHover = hoveredId === id;
  const currentIsQuestion = isQuestion(responseOption);
  const hasArrow = checkOptionInPairs(id);
  const isOptionDimmed = checkCanDimOption(responseOption);
  const showBackgroundColor = !isReviewMode && (onHover || (hasArrow && getPairedOptionsId(id).includes(hoveredId)));
  const isInCorrect = isSubmittedMode && !responseOption.isCorrect;
  const showHoverArrow = (selectedOption
    && selectedOption.id !== id
    && onHover
    && selectedOption.parent !== responseOption.parent);

  const borderColor = (() => {
    if (isSubmittedMode || isReviewMode) {
      return responseOption.isCorrect ? success : danger;
    }

    return hasArrow ? primary : gray6;
  })();

  const dotColor = (() => {
    if (isSubmittedMode || isReviewMode) {
      return responseOption.isCorrect ? success : danger;
    }

    return (selectedOption?.id === id || hasArrow) ? primary : gray5;
  })();

  const styles = css`
    &.matching-box {
      position: relative;
      width: 320px;
      border: ${isInCorrect ? 'dashed' : 'solid'} 1px ${borderColor};
      border-radius: ${halfSpacing}px;
      padding: ${standardSpacing}px;
      text-align: center;
      cursor: pointer;
      height: 100%;
      ${!isSubmittedMode && !hasArrow && css`
        box-shadow: 0px 4px 10px 0px #0000001A;
      `}

      .option-content {
        max-height: ${MAX_INPUT_HEIGHT}px;
        overflow: hidden;
      }

      .dot {
        position: absolute;
        width: ${standardSpacing}px;
        height: ${standardSpacing}px;
        border-radius: 50%;
        background-color: ${dotColor};
        z-index: 1;
      }
      .dot-right {
        right: -${halfSpacing}px;
      }
      .dot-left {
        left: -${halfSpacing}px;
      }

      ${selectedOption?.id === id && css`
        border: solid 3px ${primary};

        .dot-right {
          right: -12px;
        }
        .dot-left {
          left: -12px;
        }
      `}

      ${isOptionDimmed && css`
        opacity: 60%;
      `};

      ${!isOptionDimmed && showBackgroundColor && css`
        background-color: ${hexToRgbaString(primary, 0.05)};
      `}

      .gradiant-box {
        position: absolute;
        width: 100%;
        height: ${doubleSpacing}px;
        bottom: ${standardSpacing}px;
        background: linear-gradient(360deg, ${(!isOptionDimmed && showBackgroundColor) ? '#F3F8FE' : '#FFFFFF'} 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 1;
        pointer-events: none;
      }
    }
  `;

  const onOptionClick = () => {
    if (selectedOption?.id === id) {
      return setSelectedOption(null);
    }

    // Deleting the arrow if one already exists
    if (hasArrow) {
      const existingKey = currentIsQuestion ? id : getPairedOptionsId(id)?.[0];
      if (existingKey) {
        const newPairs = pairs;
        delete newPairs[existingKey.toString()];
        setAnswerState({ ...answerState as MatchingAnswerState, pairs });
      }
    }

    if (!isEmpty(selectedOption)
      && !isOptionDimmed
      && selectedOption.parent !== responseOption.parent
    ) {
      const questionId = currentIsQuestion ? id : selectedOption.id;
      const optionId = currentIsQuestion ? selectedOption.id : id;

      setAnswerState({
        ...answerState as MatchingAnswerState,
        pairs: { ...pairs, [questionId]: [optionId] },
      });

      setSelectedOption(null);
    } else {
      setSelectedOption(responseOption);
    }

    return null;
  };

  return (
    <React.Fragment>
      <div className='d-flex flex-column'>
        <NvTooltip
          text={getSanitizedContent(responseOption.optionContent)}
          enabled={showTooltip}
        >
          <ClickableContainer
            css={styles}
            className='matching-box d-flex justify-content-center align-items-center'
            id={getOpionId(id)}
            onMouseEnter={() => !isSubmittedMode && !isOptionDimmed && setHoveredId(id)}
            onMouseLeave={() => (!isSubmittedMode && !isOptionDimmed) && setHoveredId(null)}
            onClick={() => !(isSubmittedMode || isReviewMode) && onOptionClick()}
          >
            <ResponsivelyEmbeddedAngularHTML
              angularServices={angularServices}
              template={responseOption.optionContent}
              className='option-content'
            />
            {((onHover && !isReviewMode) || selectedOption?.id === id || hasArrow) && (
              <div className={`dot dot-${currentIsQuestion ? 'right' : 'left'} d-flex align-items-center justify-content-center text-small bold text-white`}>
                {getArrowText(responseOption)}
              </div>
            )}
            {showTooltip && (<div className='gradiant-box' />)}
          </ClickableContainer>
        </NvTooltip>

        {currentQuestionResponse?.feedback && (
          <div className={`d-flex mt-1 align-items-center text-${responseOption.isCorrect ? 'success' : 'danger'}`}>
            <div className='pl-1 mr-2'>
              <NvIcon
                size='small'
                className='feedback-icon'
                icon={responseOption.isCorrect ? 'success' : 'error'}
              />
            </div>
            <div className='text-large-body'>
              { t.SHARED[responseOption.isCorrect ? 'CORRECT' : 'INCORRECT']() }
            </div>
          </div>
        )}
      </div>

      {showHoverArrow && (
        <Xarrow
          start={getOpionId(selectedOption.id)}
          end={getOpionId(id)}
          startAnchor={isRtl() === isQuestion(selectedOption) ? 'left' : 'right'}
          endAnchor={isRtl() === isQuestion(selectedOption) ? 'right' : 'left'}
          strokeWidth={2}
          color={primary}
          showHead={false}
          showTail={false}
          path='straight'
          dashness
        />
      )}
    </React.Fragment>
  );
};

export default MatchingQuestionAnswerResponseOption;
